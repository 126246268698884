<template lang="pug">
div
  transition(name="fade")
    div(
      v-if="shown"
      v-on:mouseover="cancelScheduledHide"
      v-on:mouseout="scheduleHide"
      :class="`alert alert-${cssClass} flash text-center`"
    )
      button.close(type="button" v-on:click="shown = false")
        span
          i.fa.fa-times

      | {{ text }}
</template>

<script>
  export default {
    props: {
      type: { // notice, success, alert or danger
        type: String,
        required: true
      },
      text: {
        type: String,
        requried: true
      }
    },

    data() {
      return {
        shown: true,
        timeoutId: null
      }
    },

    computed: {
      // Convert Rails notice/alertmtypes to Bootstrap success/danger
      cssClass() {
        if(this.type === 'notice') {
          return 'success'
        } else if(this.type === 'alert') {
            return 'danger'
        } else if (this.type === 'info') {
          return 'info'
        }

        return this.type
      }
    },

    mounted() {
      this.scheduleHide()
    },

    methods: {
      scheduleHide() {
        this.cancelScheduledHide()

        this.timeoutId = setTimeout(
          () => { this.shown = false },
          3000
        )
      },

      cancelScheduledHide() {
        if(this.timeoutId) { clearTimeout(this.timeoutId) }
      }
    }
  }
</script>

